<template>
  <div class="f14 detailTemp">
    <van-collapse v-model="activeNames">
      <!-- 基本信息 -->
      <van-collapse-item class="card" :disabled="isEdit" :name="1">
        <div v-if="isEdit" slot="right-icon"></div>
        <div slot="title" class="card-title">
          <div class="card-title-l">
            <span class="f16 name">基本信息</span>
            <van-tag type="primary" :color="currDetail.groupStatus === 1 ? '#00CD00':'#FF823A'">{{currDetail.groupStatusName}}</van-tag>
          </div>
        </div>
        <div class="card-main">
          <template v-for="(item,key) in infoData">
            <info :key="key" label-width="70px" class="base-li" :item="item" :value-algin="item.align" />
          </template>
        </div>
      </van-collapse-item>
      <!-- 业务 -->
      <van-collapse-item class="card" :disabled="isEdit" :name="2">
        <div v-if="isEdit" slot="right-icon"></div>
        <div slot="title" class="card-title">
          <div class="card-title-l">
            <span class="f16 name">业务信息</span>
          </div>
        </div>
        <div class="card-main">
          <div class="li">
            <div class="li-title pointer" @click="showAddAlertFun">
              <div>群标签</div>
              <van-icon v-if="isEdit" name="arrow" class="arrowIcon" />
            </div>
            <div class="li-main">
              <div v-if="currDetail.groupTags && currDetail.groupTags.length" class="tags">
                <span v-for="it in currDetail.groupTags" :key="it.id" class="tag">{{it.tagName}}</span>
              </div>
              <div v-else class="f10" style="color:#999">暂无标签</div>
            </div>
          </div>
          <div class="li">
            <div class="li-title">
              <div>客户标签</div>
            </div>
            <div class="li-main">
              <div class="tags">
                <span v-for="it in currDetail.groupCusTags" :key="it.id" class="tag">{{it.tagName}} {{it.percentage?it.percentage+'%':''}}</span>
              </div>
            </div>
          </div>
          <!-- 活码信息 -->
          <!-- <template v-if="currDetail.groupLiveCodes && currDetail.groupLiveCodes.length">
            <template  v-for="(item,key) in currDetail.groupLiveCodes">
              <info
:key="key"
:item="{
                label: item.name,
                slotValue: true
              }"
label-width="90%"
class="base-li"
value-algin="right">
                <div slot="value" @click.stop="showQrcode(item)">
                  <van-icon name="qr"/>
                  <van-icon name="arrow" class="arrowIcon" />
                </div>
              </info>
            </template>
          </template> -->
          <!-- jicheng状态 -->
          <template>
            <info :item="extendStatus" label-width="80px" class="base-li" :value-algin="extendStatus.align"></info>
          </template>
        </div>
      </van-collapse-item>
      <!-- 群成员列表 -->
      <van-collapse-item class="card" :disabled="isEdit" :name="3">
        <div v-if="isEdit" slot="right-icon"></div>
        <div slot="title" class="card-title">
          <div class="card-title-l">
            <span class="f16 name">群成员列表</span>
          </div>
        </div>
        <div class="">
          <Scroll height="300px">
            <List ref="dataList" class="scollList" :tab-index="0" :fetch-api="groupUserList">
              <template v-slot:default="{item}">
                <div class="user-card">
                  <div class="user-card-title">
                    <div class="u-c-t-l">
                      <img class="avatarPic"  :src='item.avatar || avatarPic'>
                      <span style="margin:0 8px">{{item.name}}</span>
                      <van-tag v-if="item.attr" type="primary" class="ruleTag" :class="[ getUserColor(item.attr) ]">{{item.attrName}}</van-tag>
                    </div>
                    <div class="u-c-t-r">{{item.outGroupStatusName}}</div>
                  </div>
                  <div class="user-card-main">
                    <div v-if="item.attr === 5 " class="tags-item">
                      <div v-if="item.memberTagRels && item.memberTagRels.length" class="tags onellipsis">
                        <span v-for="it in item.memberTagRels" :key="it.id" class="tag">{{it.tagName}}</span>
                      </div>
                      <div v-else class="f10" style="color:#999">暂无标签</div>
                      <div v-if="isEdit && item.outGroupStatus === 0" class="pointer" style="padding:0 5px;"><i class="iconfont iconbianji" @click="editMemberTag(item)"/></div>
                    </div>
                    <div v-if='item.joinTime' class="u-c-m-li">入群时间：{{item.joinTime}}</div>
                    <div v-if='item.outGroupTime' class="u-c-m-li" >退群时间：{{item.outGroupTime}}</div>
                  </div>
                </div>
              </template>
            </List>
          </Scroll>
        </div>
      </van-collapse-item>
      <!-- 群监控 编辑时才有 -->
      <!-- <van-collapse-item  v-if="isEdit" class="card" :name="4">
        <div slot="right-icon"></div>
        <div slot="title" class="card-title">
          <div class="card-title-l">
            <span class="f16 name">群监控</span>
          </div>
        </div>
        <div class="card-main"> -->
          <!-- 要改造，去掉循环！！！！ -->
          <!-- v-for="it in currDetail.groupMonitors" :key='it.id' -->
          <!-- <div v-for="(val,key,index) in currDetail.groupMonitors" :key="index" class="li">
            <div class="li-title">
              <div>{{monitorsConfig[key]}}</div>
              <van-switch v-model="val.monitorFlag" :active-value="1" :inactive-value="0" size="18px" @change="changeMonitors(val.monitorFlag,val)" />
            </div>
            <div v-if="val.monitorFlag" class="li-title li-user pointer" @click="showAddUserFun(key,val)">
              <div>成员选择</div>
              <div>{{val.empIds?val.empIds.length:0}}人<van-icon name="arrow"/></div>
            </div>
          </div>
        </div>
      </van-collapse-item> -->
    </van-collapse>
    <dialog-search-list
      :fetch-api="searchAlertObj.fetchApi"
      :show-add-alerts="showAddAlert"
      :multiple="true"
      :options="{
        value: 'empId',
        name: 'name'
      }"
      :default-val='searchAlertObj.defaultVal'
      @comfirm='searchAlertObj.confirm'
@cancel='cancelUser' />
    <!-- 群活码预览图 有下载 -->
    <!-- <van-popup v-model="isShowQrcode" get-container="body" class="imgPreview">
      <van-image
        width="30vw"
        height="30vw"
        fit="contain"
        :src="downObj.url"
      />
      <div style="margin-top:20px">
        <van-button v-if="!isInWechatMP" type="info" block style="border-radius: 4px;" @click="downObj.down">下载活码</van-button>
      </div>
    </van-popup> -->
    <van-popup v-model='showTagPopup' round  :style="{ height: '50%', width: '60%' }" position='center'>
      <!-- <tags
        v-if='showTagPopup'
        :fetch-api="popObj.fetchApi"
        search-key="tagName"
        :default-value="popObj.defaultValue"
        :options="{
          value: 'id',
          name: 'tagName'
        }"
:multiple="true"
        @close='closePop'
        @saveTags='popObj.confirm'/> -->
        <div class="popup-wrap">
          <addTage :check-tag-data="currDetail.groupTags" :padding-bottom='53' @close="showTagPopup=false" @save="addTageSave"></addTage>
        </div>
    </van-popup>
  </div>
</template>
<script>
import Info from '@/components/clue/info/info.vue'
import List from '@/components/baseList/list.vue'
import Scroll from '@/components/scroll.vue'
import DialogSearchList from '@/components/dialogSearchList.vue'
// import Tags from '@/components/selectTags/tags.vue'
import avatarPic from '@/assets/img/avatar.png'
import { downloadImage } from '@/utils/utils'
import groupChatApi from '@/api/modules/cusGroupChat'
import mixin from '../mixin'
import { Toast } from 'vant'
import addTage from '@/views/cusGroupOperateSop/components/addTage.vue'

export default {
  components: { Info, List, Scroll, DialogSearchList, addTage },
  mixins: [mixin],
  props: {
    isEdit: {
      type: [Number, String, Boolean],
      default: false
    },
    detailInfo: {
      type: Object,
      default: () => ({})
    },
    id: {
      type: [Number, String],
      default: null
    }
  },
  data() {
    const that = this
    return {
      activeNames: [],
      avatarPic,
      showAddAlert: false,
      // isShowQrcode: false,
      showTagPopup: false,
      // 用户基本信息
      infoData: {
        0: {
          label: '客户群名称',
          get value() {
            return that.currDetail.name
          },
          type: 'string',
          align: 'right'
        },
        1: {
          label: '群公告',
          get value() {
            return that.currDetail.notice
          },
          type: 'string',
          labelAlign: 'top'
          // align: 'right',
        },
        2: {
          label: '群主',
          get value() {
            return that.currDetail.ownerName
          },
          type: 'string',
          align: 'right'
        },
        3: {
          label: '管理员',
          get value() {
            try {
              return that.currDetail.groupAdminNames && that.currDetail.groupAdminNames.join(',')
            }
            catch (error) {
              return ''
            }
          },
          type: 'string',
          align: 'right'
        },
        4: {
          label: '客户数量',
          get value() {
            return that.currDetail.customerNum || 0
          },
          type: 'string',
          align: 'right'
        },
        5: {
          label: '员工数量',
          get value() {
            return that.currDetail.empNum || 0
          },
          type: 'string',
          align: 'right'
        },
        6: {
          label: '创建时间',
          get value() {
            return that.currDetail.groupCreateTime
          },
          type: 'string',
          align: 'right'
        }
      },
      // 继承状态
      extendStatus: {
        label: '继承状态',
        get value() {
          return that.currDetail.statusName
        },
        type: 'string',
        align: 'right'
      },
      currDetail: {}, // 当前的详细信息
      popObj: {},
      downObj: {
        url: '',
        down: () => {}
      }, // 要下载的活码链接
      searchAlertObj: {
        confirm: () => {}
      },
      monitorsConfig: {
        group_move_cus: '客户退群监控',
        dismiss_group: '群解散监控'
      },
      groupMembersObj: {}
    }
  },
  watch: {
    detailInfo: {
      handler(val) {
        if (val) {
          this.currDetail = JSON.parse(JSON.stringify(val))
        }
      },
      immediate: true
    }
  },
  created() {
    this.isEdit && (this.activeNames = [1, 2, 3, 4])
  },
  methods: {
    // 群成员颜色标识
    getUserColor(type) {
      const obj = {
        1: {
          name: '群主',
          color: 'red'
        },
        2: {
          name: '群管理员',
          color: 'blue'
        },
        3: {
          name: '成员',
          color: 'purple'
        },
        5: {
          name: '客户',
          color: 'orange'
        }
      }
      return obj[type]?.color || ''
    },
    // 成员选择弹窗
    showAddUserFun(key, item) {
      // currDetail.groupMonitors
      // this.currEmpList=val.empIds // 已选中的成员id列表
      // this.currWatchKey=key
      this.searchAlertObj = {
        defaultVal: item.empIds || [],
        fetchApi: this.userFetchApi,
        confirm: (val, arr) => {
          item.empIds = val
          this.cancelUser()
        }
      }
      this.showAddAlert = true
    },
    // 客户列表API
    userFetchApi(params = {}) {
      const { pageNum, pageSize, paramData } = params
      const info = {
        pageNum, pageSize,
        paramData: {
          ...paramData,
          chatGroupId: Number(this.id),
          type: 1, // 1 内部人员  2 客户
          outGroupStatus: 0, // 0 在群 -1 退群
          orderFields: [{ fieldName: 'join_time', order: 'DESC' }]
        }
      }
      // 接口待修改。。。。。↓
      return groupChatApi.groupUserPageList(info)
    },
    // 保存
    saveEdit() {
      const { currDetail, groupMembersObj, monitorsConfig } = this
      const { groupMonitors, groupTags, id } = currDetail
      const monitors = JSON.parse(JSON.stringify(groupMonitors))

      return new Promise(resolve => {
        let flag = false
        for (const key in monitors) {
          const it = monitors[key]
          if (it.monitorFlag === 0) {
            it.empIds = []
          }
          else if (it.monitorFlag === 1 && ((it.empIds && !it.empIds.length) || !it.empIds)) {
            flag = true
            Toast(`【${monitorsConfig[key]}】请选择监控成员`)
            return
          }
        }
        if (!flag) {
          resolve({
            id,
            groupTagRels: groupTags,
            groupMembers: Object.values(groupMembersObj),
            groupMonitors: monitors
          })
        }
      })
      // const tagArr=[]
      // const userTagArr=[]
      // let selUserList=JSON.parse(JSON.stringify(this.changeItemList))
      // this.currDetail.groupTags.length && this.currDetail.groupTags.map(item=>{
      //   tagArr.push(this.filterInfo(item))
      // })
      // selUserList.length && selUserList.map(item=>{
      //   item.memberTagRels.length && item.memberTagRels.map(it=>{
      //     userTagArr.push(this.filterInfo(it))
      //   })
      //   item.memberTagRels=userTagArr
      // })
      // const param={
      //   id:this.currDetail.id,
      //   groupTagRels:tagArr,
      //   groupMembers:selUserList,
      //   groupMonitors:this.currDetail.groupMonitors
      // }
      // this.saveApi(param)
    },
    // 过滤数据
    // filterInfo(item){
    //   const { tagGroupId,tagId,id,groupId }=item
    //   return {
    //     tagGroupId:tagGroupId||groupId,
    //     tagId:tagId||id
    //   }
    // },
    // 获取并下载当前的活码信息
    downImg() {
      if (this.downObj.url) {
        downloadImage(this.downObj.url)
      }
      else {
        Toast('下载出错！')
      }
    },
    // async showQrcode(item) {
    //   const res = await groupChatApi.getLiveCode({ id: item.id }).then(this.thenResolve)
    //   this.downObj = {
    //     url: res.url,
    //     down: () => {
    //       this.downImg(item)
    //     }
    //   }
    //   this.downObj.url && (this.isShowQrcode = true)
    // },
    // 群成员接口
    groupUserList(params = {}) {
      const info = {
        ...params,
        paramData: {
          chatGroupId: Number(this.id),
          orderFields: [
            {
              fieldName: 'join_time',
              order: 'DESC'
            }
          ]
        }
      }
      return groupChatApi.groupUserList(info)
    },
    // 编辑群标签
    showAddAlertFun() {
      if (!this.isEdit) return
      const { groupTags } = this.currDetail
      const defaultValue = this.getArrToObj(groupTags || [])
      this.popObj = {
        defaultValue,
        fetchApi: (params = {}) => {
          return this.getTagList({
            ...params,
            type: 10003,
            subType: 1000301
          })
        },
        confirm: (val, arr) => {
          // if (arr.length){
          this.currDetail.groupTags = this.getGroupTag(arr)
          // }
          this.closePop()
        }
      }
      this.showTagPopup = true
    },
    getArrToObj(arr, key = 'tagId') {
      const obj = {}
      arr.map(item => {
        obj[item[key]] = item
      })
      return obj
    },
    getGroupTag(arr) {
      return arr.map(item => {
        if (item.tagGroupId) {
          return item
        }
        return {
          tagGroupId: item.groupId,
          tagId: item.id,
          tagName: item.tagName
        }
      })
    },
    // 当监控开启，没有选择时，默认选择群主
    changeMonitors(flag, item) {
      const { empId } = this.currDetail
      const { empIds } = item
      if (flag && ((empIds && empIds.length) || !empIds) && empId) {
        item.empIds = [empId]
      }
    },
    closePop() {
      this.showTagPopup = false
    },
    // 编辑群客户标签
    editMemberTag(item) {
      const { memberTagRels, id } = item
      const defaultValue = this.getArrToObj(memberTagRels || [])
      this.popObj = {
        defaultValue,
        fetchApi: (params = {}) => {
          return this.getTagList({
            ...params,
            type: 10002,
            subType: 3
          })
        },
        confirm: (val, arr) => {
          // if (arr.length){
          item.memberTagRels = this.getGroupTag(arr)
          this.groupMembersObj[id] = item
          // }
          this.closePop()
        }
      }
      this.showTagPopup = true
    },
    cancelUser() {
      this.showAddAlert = false
    },
    addTageSave(list) {
      this.currDetail.groupTags = list
      this.showTagPopup = false
    }
  }
}
</script>
<style lang="less" scoped>
@blue:#0088FF;
@border:#E3E6F0;
.flexv(@justify:center,@align:center) {
  display: flex;
  justify-content: @justify;
  align-items: @align;
}
.detailTemp{
  /deep/ .van-collapse-item__content{
    padding: 0;
    color: unset;
  }
}
/deep/ .popup-wrap{
  height: calc(100% - 53px);
  overflow: auto;
  padding: 40px 40px 0 40px;
}
.card{
  margin-bottom: 16px;
  .card-title{
    .flexv(space-between);
    .card-title-l{
      // position: relative;
      display: flex;
      align-items: center;
      &::before{
        content: "";
        width: 2px;
        height: 12px;
        background: @blue;
        margin-right: 6px;
        // display: inline-block;
      }
    }
    .name{
      margin-right: 12px;
      color: #333;
    }
    .arrow{
      color: @blue;
    }
  }
  .card-main{
    padding: 0 16px;
    .base-li{
      border-bottom: 1px solid #F5F5F5;
      padding: 8px 0;
      font-size: 14px;
      /deep/ .value{
        color: #999;
      }
    }
    .li{
      border-bottom: 1px solid #F5F5F5;
      .li-title{
        .flexv(space-between);
        padding: 10px 0;
      }
      .arrowIcon,.li-user{
        color:#999;
      }
    }
  }
}
.tags{
  // display: flex;
  .tag{
    display: inline-block;
    background: #E6F3FF;
    color: @blue;
    margin:0 6px 6px 0;
    font-size: 12px;
    padding: 4px 10px;
  }
}
.user-card{
  background: #F4F8FE;
  padding: 12px;
  .user-card-title{
    .flexv(space-between);
    padding-bottom: 8px;
    border-bottom: 1px solid @border;
    .avatarPic{
      width: 24px;
      height: 24px;
    }
    .u-c-t-l{
      display: flex;
      align-items: center;
      .ruleTag{
        border-radius: 2px;
        &.red{
          @font:#FF5257;
          @bg:#F5E7ED;
          background: @bg;
          color: @font;
          border:1px solid @font;
        }
        &.orange{
          @font:#FF823A;
          @bg:#F5ECEA;
          background: @bg;
          color: @font;
          border:1px solid @font;
        }
        &.blue{
          @bg:#DCEDFE;
          background: @bg;
          color: @blue;
          border:1px solid @blue;
        }
        &.purple{
          @font:#8053FF;
          @bg:#E8E8FE;
          background: @bg;
          color: @font;
          border:1px solid @font;
        }
      }
    }
    .u-c-t-r{
      color: #999;
    }
  }
  .user-card-main{
    padding-top: 8px;
    .u-c-m-li:not(:last-child){
      margin-bottom: 8px;
    }
    .tags-item{
      .flexv(space-between,flex-start);
    }
  }
}
.imgPreview{
  background: none;
}
.scollList{
  /deep/ .item{
    width: 100% !important;
  }
}
</style>
