const gateway = '/dapi/api-gateway/h5/scrm-qwagg-service'
import { get, post } from '@/api/index'

export const cus_get_list = (params) => {
  return post(gateway + `/api/v1/sop/sopinfoBase/get_group_sop_page`, params)
}

export const cus_add = (params) => {
  return post(gateway + `/api/v1/sop/sopinfoBase/add`, params)
}

export const cus_get_detail_by_id = (id) => {
  return get(gateway + `/api/v1/sop/sopinfoBase/get_detail_by_id?id=${id}`)
}

export const cus_update = (params) => {
  return post(gateway + `/api/v1/sop/sopinfoBase/update`, params)
}

export const cus_Gropp = (params) => {
  return post(gateway + `/api/v1/admin/chat/group/get_list_by_params`, params)
}

// 客户群列表接口
export const cus_Grop_new = (params) => {
  return post(gateway + `/api/v1/group/find_page_by_param`, params)
}

// 查询字典
export const getdict = (params) => {
  return post(gateway + `/api/v1/tenant/dictionaries/get_list_by_param`, params)
}

export const tagsPreview = (params) => {
  return post(gateway + `/api/v1/task/tag/sum_tag_rule`, params)
}

export const getTagsBase = (params) => {
  return post(gateway + `/api/v1/cus/tagGroupBase/get_list_by_param`, params)
}
// 小程序list
export const appletApi = (params) => {
  return post(gateway + `/api/v1/tenant/miniprogramBase/get_list_by_param`, { params })
}
// 标签组合标签列表
export const getTagsBaseList = (params) => {
  return post(gateway + `/api/v1/cus/tagBase/get_list_by_param`, params)
}
