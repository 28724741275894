<template>
  <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
    <van-list
      v-model="loading"
      class="list"
      :finished="finished"
      :finished-text="finishedText"
      offset='50'
      @load="onLoad"
    >
      <div class="ul">
        <div v-for="(item,index) in list" :key="index" class="item">
          <slot :item="item" :index="index" />
        </div>
      </div>
    </van-list>
  </van-pull-refresh>
</template>
<script>
export default {
  components: {
  },
  props: {
    pageSize: {
      type: Number,
      default: 20
    },
    fetchApi: {
      type: Function,
      default() {}
    },
    tabIndex: {
      type: Number,
      default: 0
    }
  },
  data() {
    const that = this
    return {
      page: {
        pageNum: 1,
        pageSize: that.pageSize
      },
      list: [],
      loading: false,
      finished: false,
      finishedText: '没有更多了',
      totalCount: 0,
      isLoading: false
    }
  },
  created() {

  },
  mounted() {
    this.getList()
  },
  methods: {
    resetting() {
      this.page.pageNum = 1
      this.getList()
    },
    async getList() {
      const { page } = this
      const res = await this.fetchApi(page).then(this.thenResolve)
      this.loading = false
      if (page.pageNum === 1) {
        this.list = res.dataList
      }
      else {
        this.list = this.list.concat(res.dataList)
      }
      this.totalCount = res.page.totalCount
      if (this.list.length >= this.totalCount) {
        this.finished = true
      }
      else {
        this.finished = false
      }
    },
    onLoad() {
      if (this.list.length < this.totalCount && this.totalCount !== 0) {
        this.page.pageNum++
        this.getList()
      }
    },
    onRefresh() {
      this.isLoading = false
      this.resetting()
    }
  }
}
</script>
<style lang="less" scoped>
.list{
  padding-bottom: 20px;
}
.ul{
  padding: 8px 6px 18px;
  display: flex;
  flex-wrap: wrap;
  .item{
    margin-bottom: 16px;
    padding: 0 10px;
    box-sizing: border-box;
    width: 100%;
  }
  @media screen and (min-width: 750px) {
    .item {
      width: 50%;
    }
  }
  @media screen and (min-width: 1080px) {
    .item {
      width: 33.33%;
    }
  }
  @media screen and (min-width: 1540px) {
    .item {
      width: 25%;
    }
  }
}

</style>
