import tagBaseApi from '@/api/modules/tagBase'
import groupChatApi from '@/api/modules/cusGroupChat'
import { isMobileClient } from '@/utils/utils'

export default {
  data() {
    return {
      isMobile: isMobileClient()
    }
  },
  methods: {
    getTagList(params = {}) {
      return tagBaseApi.getTagList(params)
    },
    getDetail(id) {
      return groupChatApi.getDetail({ id }).then(this.thenResolve)
    },
    goBackPage(externalUserid = '') {
      // if (this.isMobile || externalUserid) {
      this.$router.back()
      // }
      // else {
      //   this.$emit('change', false)
      // }
    },
    // 打开会话
    openQwChat(chatId) {
      if (chatId) {
        wx.openEnterpriseChat({
          userIds: '', // 参与会话的企业成员列表，格式为userid1;userid2;...，用分号隔开。
          externalUserIds: '', // 参与会话的外部联系人列表，格式为userId1;userId2;…，用分号隔开。
          groupName: '', // 会话名称。单聊时该参数传入空字符串""即可。
          chatId, // 若要打开已有会话，需指定此参数。如果是新建会话，chatId必须为空串
          success: function(res) {
            // var chatId = res.chatId // 返回当前群聊ID，仅当使用agentConfig注入该接口权限时才返回chatId
            // 回调
          },
          fail: function(res) {
            if (res.errMsg.indexOf('function not exist') > -1) {
              alert('版本过低请升级')
            }
            else {
              console.log(res, 'fail-----------')
            }
          }
        })
      }
    }
  }
}
