<template>
  <van-dialog
v-model='showAddAlert'
:style='dialogStyle'
:title='dialogProps.title?dialogProps.title:"提示"'
show-cancel-button
              :overlay-style="{ background: 'rgba(0,0,0,.2)' }"
get-container='body'
:before-close="beforeClose"
@confirm='confirmEvent'
              @cancel='cancel'>
    <van-form ref="form" validate-first>
      <template v-for="(item,index) in formArr" >
        <van-field :key="index" v-model="item.value"  maxlength='200' :label='item.label' :name="item.key" placeholder='请输入' />
      </template>
    </van-form>
    <p v-if="dialogProps.tips" class="tips">{{dialogProps.tips}}</p>
    <div>
      <!-- 搜索框 -->
      <van-search
        v-model='searchValue'
        :placeholder='dialogProps.placeholder'
        @search='onSearch'
        @clear='onSearch'
      >
      </van-search>
    </div>
    <div class='ul'>
      <van-list
        ref='vanList'
        v-model='loading'
        :finished='finished'
        :immediate-check='false'
        finished-text='没有更多了'
        offset='50'
        @load='onLoad'
      >
        <van-field name='userIds' label=''>
          <template #input>
            <van-checkbox-group v-if="multiple" v-model="userIds">
              <van-checkbox
v-for='item in list'
:key='item[options.value]'
:name='item[options.value]'
                            class='businessRadio'>
                <div>{{ item[options.name] }}</div>
              </van-checkbox>
            </van-checkbox-group>
            <van-radio-group v-else v-model='userId' direction='vertical'>
              <van-radio
v-for='item in list'
:key='item[options.value]'
:name='item[options.value]'
                         :disabled="item[options.value] == dialogProps.disabledVal"
                         class='businessRadio'>
                <div v-if="itemNode" class="onellipsis content" v-html="itemNode(item)"></div>
                <div v-else class="onellipsis content" v-html="item[options.name]"></div>
              </van-radio>
            </van-radio-group>
          </template>
        </van-field>
      </van-list>
    </div>
  </van-dialog>
</template>

<script>
export default {
  props: {
    showAddAlerts: {
      type: Boolean,
      default: false
    },
    options: {
      type: Object,
      default: () => ({
        value: 'id',
        name: 'name'
      })
    },
    searchKey: {
      type: String,
      default: 'name'
    },
    dialogProps: {
      type: Object,
      default: () => ({
        title: '提示',
        placeholder: '请输入'
      })
    },
    dialogStyle: {
      type: Object,
      default: () => ({
        minHeight: '400px'
      })
    },
    itemNode: {
      type: [Boolean, Function],
      default: false
    },
    fetchApi: {
      type: Function,
      default() {}
    },
    pageSize: {
      type: Number,
      default: 10
    },
    multiple: {
      type: Boolean,
      default: false
    },
    formArr: {
      type: Array,
      default: () => ([])
    },
    defaultVal: {
      type: [Object, Array],
      default: () => ({})
    }
  },
  data() {
    // const that = this
    return {
      showAddAlert: false,
      userId: '',
      userIds: [],
      loading: false,
      finished: false,
      page: {
        pageNum: 1,
        pageSize: this.pageSize
      },
      list: [],
      searchValue: ''
      // isPhone: isMobileClient()
    }
  },
  watch: {
    showAddAlerts: {
      handler(newVal) {
        if (newVal) {
          this.searchValue = ''
          this.init()
          this.getFetchApi()
          this.userId = this.dialogProps.defaultId || ''
        }
        this.showAddAlert = newVal
      },
      immediate: true
    }
  },
  mounted() {
  },
  methods: {
    init() {
      this.list = []
      this.loading = false
      this.finished = false
      this.userIds = this.defaultVal || []
      this.userId = ''
      this.page = {
        pageNum: 1,
        pageSize: this.pageSize
      }
    },
    async getFetchApi() {
      const { pageNum, pageSize } = this.page
      const data = await this.fetchApi({
        pageNum,
        pageSize,
        paramData: {
          [this.searchKey]: this.searchValue
        }
      }).then(this.thenResolve)
      this.loading = false

      const { dataList, page } = data
      if (pageNum === 1) {
        this.list = dataList
      }
      else {
        this.list = this.list.concat(dataList)
      }
      if (this.list.length >= page.totalCount) {
        this.finished = true
      }
      // }
      /*
      dataList: []
      page:
      pageCount: 0
      pageIndex: 1
      pageSize: 10
      totalCount: 0
      */
    },
    onSearch() {
      this.init()
      this.getFetchApi()
    },
    onLoad() {
      if (this.list.length) {
        this.page.pageNum++
        this.getFetchApi()
      }
    },
    $validate() {
      // 验证表单
      const { validate, getValues } = this.$refs.form
      return validate().then(res => {
        return getValues()
      })
    },
    async confirmEvent() {
      // this.showAddAlert = false
      const formValue = await this.$validate()
      if (this.userIds.length || this.userId) {
        let data = []
        if (this.multiple) {
          data = this.list.filter(item => this.userIds.includes(item[this.options.value]))
          this.$emit('comfirm', this.userIds, data, formValue)
        }
        else {
          data = this.list.filter(item => item[this.options.value] === this.userId)
          this.$emit('comfirm', this.userId, data, formValue)
        }
      }
      else {
        this.$toast('请选择')
      }
    },
    beforeClose(action, done) {
      this.showAddAlerts ? done(false) : done()
    },
    cancel() {
      this.searchValue = ''
      // this.showAddAlert = false
      this.$emit('cancel', this.showAddAlert)
    }
  }
}
</script>

<style lang='less' scoped>
@deep: ~">>>";
.businessRadio {
  margin-bottom: 6px;

  // @{deep} .van-radio__label {
  //   // width: 80%;
  // }
}

.dialogBox {
  height: 400px;
}

.dialogBoxPc {
  width: 500px;
  height: 400px;
}
.tips {
    font-size: 13px;
    padding: 4px 12px;
    color: #08f;
}

.ul {
  height: 250px;
  overflow: scroll;

  @{deep} .van-field {
    &:after {
      border: 0;
    }
  }
  .content{
    width: 250px;
  }
}
</style>
