<template>
  <div class="formBox">
    <!-- <h3 v-if="isShowLabel" :class="['formLabel', { formRequired: formJson.required }]" style="margin-bottom:12px;">{{ formJson.screenKey }}</h3> -->
    <van-field
      :label="formJson.screenKey"
      readonly
      clickable
      name="picker"
      :value="name"
      :placeholder="formJson.placeholder || '请选择'"
      class="input"
      input-align="right"
      @click="showPicker = true"
    >
      <template #right-icon>
        <van-icon v-if="name" name="close" @click.stop="brandSystemDelete" />
        <van-icon v-else name="arrow" />
      </template>
    </van-field>
    <van-popup v-model="showPicker" position="bottom">
      <van-picker
        ref="picker"
        show-toolbar
        :columns="formJson.screenValue"
        :value-key="formJson.labelKey || this.labelKey"
        @confirm="onConfirm"
        @cancel="showPicker = false"
      />
    </van-popup>
  </div>
</template>
<script>
export default {
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    formJson: {
      type: Object,
      default: () => ({})
    },
    value: {
      type: [Number, String],
      default: ''
    },
    isShowLabel: {
      type: [Boolean, String, Number],
      default: true
    }
  },
  data() {
    return {
      showPicker: false,
      dataObj: {},
      name: '',
      labelKey: 'name',
      valueKey: 'code'
    }
  },
  watch: {
    value: {
      handler(val) {
        const { screenValue = [], valueKey = this.valueKey, labelKey = this.labelKey } = this.formJson
        if (val === '') {
          this.name = ''
          //   this.dataObj = {}
        }
        else {
          const obj = screenValue.find(item => {
            return item[valueKey] === val
          })
          obj && (this.name = obj[labelKey])
        }
      },
      immediate: true
    }
  },
  methods: {
    brandSystemDelete() {
      this.$emit('change', '')
    },
    onConfirm(value, index) {
      const { valueKey = this.valueKey } = this.formJson
      this.$emit('change', value[valueKey], index, value)
      this.showPicker = false
    }
  }
}
</script>
<style lang="less" scoped>
.input{
  padding:10px 0;
  /deep/ .van-field__label{
    color: #262626;
    font-size: 14px;
  }
  // border: 1px solid #cfd5de;
  // padding:4px 8px;
  // border-radius: 2px;
  // margin-top:12px;
}
</style>
