// 群管理
const gateway = '/dapi/api-gateway/h5/scrm-qwagg-service'
import { get, post } from '@/api/index'

export default {
  // 列表接口
  pageList(data, headers = {}) {
    return post(gateway + '/api/v1/group/get_expand_page_by_param', data)
  },

  // 群主列表
  groupOwnerList(data, headers = {}) {
    return post(gateway + '/api/v1/md/org/employee/get_page_by_param', data)
  },

  // 获取用户详细信息
  getDetail(params, headers = {}) {
    return get(gateway + '/api/v1/group/get_detail_by_id', params)
  },
  // 获取企微群成员信息
  groupUserList(data, headers = {}) {
    return post(gateway + '/api/v1/group/member/get_expand_page_by_param', data)
  },
  // 获取企微群成员列表 分页
  groupUserPageList(data, headers = {}) {
    return post(gateway + '/api/v1/group/member/get_page_by_param', data)
  },
  // 获取当前群活码信息
  getLiveCode(params, headers = {}) {
    return get(gateway + '/api/v1/group/livecode/download', params)
  },

  // 保存
  seveAPI(data, headers = {}) {
    return post(gateway + '/api/v1/group/update', data)
  }
}
