<template>
  <div id="scrollView" class="scroll" :style="{height:height}">
    <slot></slot>
  </div>
</template>
<script>
export default {
  props: {
    height: {
      type: String,
      default: '100vh'
    }
  },
  mounted() {

  }
}
</script>
<style lang="less" scoped>
.scroll{
    // height: 100vh;
    overflow-y: auto;
    &::-webkit-scrollbar {
        display: none;
    }
}
</style>
