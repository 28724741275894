<template>
  <div class="info f12" :class="[item.labelAlign || '']">
    <div class="label" :style="{width: labelWidth}">{{item.label}}</div>
    <div class="value" :style="{textAlign: valueAlgin}">
      <div v-if="item.type === 'tag'" class="tags" :class="[item.class||'']" @click.stop="item.click ? item.click() :''">
        <template v-for="(it,index) in item.value">
          <div v-if="item.option && item.option.name?it[it.option.name]: it" :key="index" class="tag-li">
            <span v-if="item.option && item.option.name">{{it[it.option.name].length>10?it[it.option.name].substr(0,10)+'...':it[it.option.name]}}</span>
            <span v-else>{{it.length>10?it.substr(0,10)+'...':it}}</span>
          </div>
        </template>
      </div>
      <div v-else-if="item.type === 'select'" class="select">
        <Picker
v-model="item.value"
:is-show-label="false"
:form-json="{
          screenValue: item.option.select.arr,
          labelKey: item.option.select.option.key || 'name',
          valueKey: item.option.select.option.value || 'id'
        }"  />
      </div>
      <slot v-else-if="item.slotValue" name="value"></slot>
      <!-- 群客户标签 带有百分比-->
      <!-- <div v-else-if="item.type==='percentage'" class="tags" :class="[item.class||'']">
        <template v-for="(it,index) in item.value">
          <div v-if="item.option && item.option.name?it[it.option.name]: it" :key="index" class="tag-li">
            {{it.name?it.name:''}} {{it.percentage?it.percentage+'%':''}}
          </div>
        </template>
      </div> -->
      <div v-else @click.stop="item.click?item.click:''" v-html="item.value"></div>
    </div>
  </div>
</template>
<script>
import Picker from '@/components/clue/picker.vue'
export default {
  components: {
    Picker
  },
  props: {
    labelWidth: {
      type: String,
      default: '60px'
    },
    valueAlgin: {
      type: String,
      default: 'left'
    },
    item: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
    }
  },
  watch: {
    'item.value'(val) {
      this?.item?.change && this?.item?.change(val)
    }
  },
  methods: {

  }
}
</script>
<style lang="less" scoped>
@blue:#08f;
@orange:#ff823a;
@green: #00CD00;
@red: #FF5257;
.info{
  display: flex;
  line-height: 25px;
  &.top{
    display: block;
  }
  .value{
    flex: 1;
  }
  .tags{
    .tag-li{
      margin-right: 8px;
      display: inline-block;
      padding: 0 3px;
      font-size: 10px;
      line-height: 16px;
      color: @blue;
      border-radius: 2px;
      border: 1px solid @blue;
    }
    &.orangeTags .tag-li{
      color: @orange;
      border-color: @orange;
    }
    &.greenTags .tag-li{
      color: @green;
      border-color: @green;
    }
    &.redTags .tag-li{
      color: @red;
      border-color: @red;
    }
  }
  .select{
    // width: 100px;
    display: inline-block;
    /deep/ .input{
      padding: 0 8px;
      border:0;
      &:after{
        border: 0;
      }
    }
  }
}
  .infoItemValue {
      flex: 1;
      text-align: right;
      color: #262626;
      .infoclick{
        color:#0087ff ;
      }
    }

</style>
