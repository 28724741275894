<template>
  <div :style="{'padding-bottom':paddingBottom +'px'}">
    <el-input v-model="tagName" placeholder="请输入标签内容" @change="inputChange"></el-input>
    <div class="tage-wrap">
      <div v-for="(item,index) in tageList" :key="index" class="tage-item">
        <div class="tage-title">{{item.tagGroupName}}</div>
        <div class="tage-content">
          <el-tag v-for="(m) in item.tagList" :key='m.tagId' :effect="tagIds.indexOf(m.tagId)=== -1? 'plain':'dark'" @click="clickTag(m)">{{ m.tagName }}</el-tag>
        </div>
      </div>
    </div>
    <div class="editformBtn" :style="{ position: 'absolute',left: 0, bottom: 0,right: 0,zIndex: 9,borderTop: '1px solid #e9e9e9',padding: '10px 20px', background: '#fff',textAlign: 'right',}">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="saveDialog">确 定</el-button>
    </div>
  </div>
</template>
<script>
import { getTagsBaseList } from '@/api/modules/cusGroupOperateSop'
export default {
  name: 'AddTage',
  props: {
    checkTagData: {
      type: Array,
      default: () => []
    },
    paddingBottom: {
      type: Number,
      default: () => 0
    }
  },
  data() {
    return {
      tagName: '',
      tageList: [],
      tagIds: [], // 选中标签的ids
      tagCheckList: [] // 选中标签的数据集合
    }
  },
  created() {
    this.getTageList()
    if (this.checkTagData && this.checkTagData.length) {
      this.checkTagData.forEach((row) => {
        this.tagIds.push(row.tagId)
        this.tagCheckList.push(row)
      })
    }
  },
  methods: {
    async getTageList() {
      const { data } = await getTagsBaseList({ tagName: this.tagName })
      this.tageList = data
    },
    closeDialog() {
      this.$emit('close')
    },
    saveDialog() {
      this.$emit('save', this.tagCheckList)
    },
    inputChange() {
      this.getTageList()
    },
    clickTag(data) {
      if (this.tagIds.indexOf(data.tagId) === -1) {
        this.tagIds.push(data.tagId)
        this.tagCheckList.push(data)
      }
      else {
        const index = this.tagIds.indexOf(data.tagId)
        this.tagIds.splice(index, 1)
        this.tagCheckList.splice(index, 1)
      }
    }
  }
}
</script>
<style lang="less" scoped>
.tage-wrap{
  padding: 10px;
  .tage-item{
    margin-top: 4px;
    .tage-title{
      color: #333333;
      padding: 6px 0;
    }
    .tage-content{
      padding: 0 4px;
    }
  }
}
/deep/ .el-tag--small{
  margin-left: 4px;
  margin-top: 4px;
}
.editformBtn {
  text-align: right;
}
</style>
