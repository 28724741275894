<template>
  <div class="detail">
    <detail-temp :id='currId' ref="detail" style="height:calc(100% - 72px);overflow-y:auto;" :detail-info='detailInfo' :is-edit='isEdit'/>
    <div class='subBtnBox'>
      <div class='backSubBtn pointer left' block @click='goBackPage("")'>返回</div>
      <div class='backSubBtn pointer right' block @click='saveEdit'>确 定</div>
    </div>
  </div>
</template>
<script>
import DetailTemp from './component/detailTemp.vue'
import mixin from './mixin'
import groupChatApi from '@/api/modules/cusGroupChat'
export default {
  components: { DetailTemp },
  mixins: [mixin],
  model: {
    prop: 'show',
    event: 'change'
  },
  props: {
    id: {
      type: [String, Number],
      default: ''
    },
    show: {
      type: [Boolean, Number, String],
      default: false
    }
  },
  data() {
    return {
      detailInfo: {},
      currId: '',
      isEdit: true
    }
  },
  created() {
    const { id } = this.$route.query
    id && (this.currId = id)
    this.id && (this.currId = this.id)
    // console.log(this.currId,'传来的id')
    if (this.currId) {
      this.getDetail(this.currId).then(res => {
        this.detailInfo = res
      })
    }
  },
  methods: {
    saveEdit() {
      this.$refs.detail.saveEdit().then(params => {
        this.saveApi(params).then(res => {
          this.goBackPage('')
          this.pageUpdate()
        })
      })
    },
    pageUpdate() {
      if (this.isMobile) {
        sessionStorage.setItem('isRefresh', 1)
      }
      else {
        this.$emit('pageUpdate')
      }
    },
    // 保存调接口
    saveApi(param = {}) {
      return groupChatApi.seveAPI(param).then(this.thenResolve)
    }
  }
}
</script>
<style lang="less" scoped>
.detail{
  height: 100vh;
  padding: 16px;
  box-sizing: border-box;
  background: #f5f5f5;
}
.subBtnBox {
  padding-top: 10px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  background: #f5f5f5;
  .backSubBtn {
    box-sizing: border-box;
    width: 40%;
    height: 40px;
    line-height: 38px;
    text-align: center;
    border-radius:4px;
    font-size: 16px;
    font-weight: 500;
  }

  .left {
    background: #FFFFFF;
    color: #0088FF;
    border: 1px solid #0088FF;
  }

  .right {
    margin-left: 23px;
    background: #0088FF;
    color: #FFFFFF;
  }
}
</style>
